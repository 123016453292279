/*=================================================================
  Single Blog Page
==================================================================*/


#blog-banner {
  padding-bottom: 100px;
  padding-top: 150px;
}

.blog-title h1 {
}

#blog-page {
  margin: 0 0 40px;
}






.post-meta {
  font-size: 13px;
  margin: 20px 0 0;
  padding: 0 20px 10px;
  a {
    color: $primary-color;
  }
  > span {
    color: #666;
    border-right: 1px solid #dedede;
    display: inline-block;
    font-size: 13px;
    margin: 10px 10px 0 0;
    padding-right: 10px;
    &:last-child {
      border-right: 0;
    }
    i {
      margin-right: 5px;
    }
  }
}


.post-excerpt {
  padding: 0 20px;
  margin-bottom: 60px;
  h3 {
    a {
      color: #000;
    }
  }
  blockquote {
    line-height: 22px;
    margin: 20px 0;
    font-size: 16px;
  }
}


.single-blog {
    background-color: #fff;
    margin-bottom: 50px;
    padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
  a {
    color: #000;
    &:hover {
      color: $primary-color;
    }
  }
  .prev-post i {
    margin-right: 10px;
  }

  .next-post i {
    margin-left: 10px;
  }
}

.social-profile {
  ul {
   li {
      margin: 0 10px 0 0;
      display: inline-block;
      a {
        color: #4e595f;
        display: block;
        font-size: 16px;
        i {
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.comments-section {
  margin-top: 35px;
}


.author-about {
  margin-top: 40px;
}
.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}



.comment-list {
  ul {
    margin-top: 20px;
    li {
      margin-bottom: 20px;
    }
  }
}


.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
  .author-avatar {
    margin-right: 10px;
  }
  .media {
    .media-heading {
      font-size: 16px;
      margin-bottom: 8px;
      a {
        color: $primary-color;
        font-size: 13px;
      }
    }
    .comment-meta {
      font-size: 12px;
      color: #888;
    }
    p {
      margin-top: 15px;
    }
  }

}


.comment-reply-form {
  margin-top: 80px;
  input,textarea {
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    &:focus {
      box-shadow:none;
      border:1px solid $primary-color;
    }
  }
  textarea,.btn-main {
    height: auto;
  }
}

                            



