/*=================================================================
  Navigation
==================================================================*/

.navigation {
  background-color: #ffffff;
  width: 100%;
  z-index: 99;
  margin-bottom: 0;
  padding:10px;
  .logo {
    padding: 0;
    height: auto;
    margin-top:0;
    img {
      height: auto;
      width: 140px;
    }
  }
  .navigation-menu {
    li {
      &.active {
        a {
          color:$primary-color;
        }
      }
      a {
        font-size: 16px; 
        color:#737f8a;
        &:hover , &:focus {
          background: transparent;
          color:$primary-color;
        }
      }
    }
  }
}

.navbar-toggle {
  border:1px solid $white;
  border-radius:0;
  span {
    background: $white;
  }
}



